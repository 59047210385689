import React from "react";

// state
import { Link } from "react-router-dom";

// constants
import db from "../../constants/database";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";

type ItemType = {
  name: string;
  key: number;
  link: string;
};

const service: ItemType[] = [
  { name: "Executive Search", key: 1, link: "/services/executive-search" },
  { name: "Corporate Services", key: 2, link: "/services/corporate-service" },
  {
    name: "Permanent & Contract Staffing",
    key: 3,
    link: "/services/permanent-contract",
  },
];

const about: ItemType[] = [
  { name: "Origin", key: 1, link: "/origin" },
  { name: "Team", key: 2, link: "/teams" },
  { name: "Job Marketplace", key: 3, link: "/marketplace" },
  { name: "FAQs", key: 4, link: "/faqs" },
  { name: "25th Blue", key: 5, link: "https://25thblue.com" },
];

const FooterComponent = () => {
  return (
    <footer className="font-abel">
      <div className="py-4 px-5 flex justify-between md:px-[80px] flex-wrap md:py-10 md:flex md:justify-between text-[white] blue-bg-gradient">
        <div className="my-9 text-[white]">
          <div className="mb-4 ">
            <Link
              to="/talk-to-us"
              className="font-bold text-[18px] underline underline-offset-4"
            >
              Contact
            </Link>
          </div>
          {/* <p className='md:text-[19px] text-[14px]'>1681 Olakunle Bakare close</p>
          <p className='md:text-[19px] text-[14px]'>off Sanuni Fafunwa</p>
          <p className='md:text-[19px] text-[14px]'>Victoria Island</p>
          <p className='md:text-[19px] text-[14px] mb-2'>Lagos</p> */}
          <div className="mb-[0.3rem]">
            <a href="tel:+2349133779831" className="md:text-[19px] text-[14px]">
              +234 913 377 9831
            </a>
          </div>
          <div className="mb-[0.3rem]">
            <a href="tel:+7136365773" className="md:text-[19px] text-[14px]">
              +713 636 5773
            </a>
          </div>
          <div className="mb-[0.3rem]">
            <a
              href="mailto:info@25thmail.com"
              className="md:text-[19px] text-[14px]"
            >
              info@25thmail.com
            </a>
          </div>
          <div className="mb-[0.3rem]">
            <p className="md:text-[19px] text-[14px]">
              NGN: 9th Floor, 2, Idowu Taylor Street, Victoria Island, Lagos.
            </p>
          </div>
          <div>
            <p className="md:text-[19px] text-[14px]">
              USA: 3527 Mount Diablo Blvd, Lafayette, CA 94549, USA.
            </p>
          </div>
        </div>

        <div className="my-9">
          <div className="mb-4">
            <Link
              to="/services"
              className="font-bold text-[18px] underline underline-offset-4"
            >
              Services
            </Link>
          </div>
          <ul>
            {service?.map((item) => (
              <li key={item.key} className="mb-2 md:text-[19px] text-[14px]">
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="md:text-left my-9">
          <h2 className="font-bold text-[18px] mb-4 underline underline-offset-4">
            Quick Links
          </h2>
          <ul>
            {about?.map((item) => (
              <li key={item.key} className="mb-2 md:text-[19px] text-[14px]">
                <Link to={item.link}>{item.name}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="border-[white] border-l-2 md:pl-4 pl-6 my-9">
          <div className="md:h-[130px] md:relative">
            <img
              src={db.icon_white}
              alt="25th and Staffing Badge"
              className="h-[80px] md:h-[130px] mb-3 md:absolute md:top-[-20px]"
            />
          </div>
          <div className="flex flex-wrap justify-start md:justify-center items-center">
            <div className="p-1 border mx-1 rounded-full border-[white] cursor-pointer hover:border-[#243677]">
              <a
                href="https://mobile.twitter.com/25thstaffing"
                target="__blank"
                rel="noreferrer"
              >
                <img
                  src={db.twitter}
                  alt="twitter 25th and Staffing"
                  className="w-[16px] h-[16px]"
                />
                {/* <FaTwitter color='#fff' fontSize="1.0rem" /> */}
              </a>
            </div>
            <div className="p-1 border mx-1 rounded-full border-[#ffffff] cursor-pointer hover:border-[#243677]">
              <a
                href="https://m.facebook.com/people/25th-and-Staffing/100065267672006/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook color="#ffffff" fontSize="1.0rem" />
              </a>
            </div>
            <div className="p-1 border mx-1 rounded-full border-[#ffffff] cursor-pointer hover:border-[#243677]">
              <a
                href="https://www.instagram.com/25thandstaffing/?hl=en"
                target="__blank"
                rel="noreferrer"
              >
                <FaInstagram color="#ffffff" fontSize="1.0rem" />
              </a>
            </div>
            <div className="p-1 border mx-1 rounded-full border-[#ffffff] cursor-pointer hover:border-[#243677]">
              <a
                href="https://ng.linkedin.com/company/25thandstaffing-com"
                target="__blank"
                rel="noreferrer"
              >
                <FaLinkedin color="#ffffff" fontSize="1.0rem" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-sm md:text-[16px] flex items-center justify-center px-2 py-3 md:px-4 md:py-5 text-[#272727] text-center">
        &#169; 25th &amp; Staffing 2025.&nbsp;
        <Link to="/policy" className="text-[#243677] cursor-pointer">
          Privacy Policy
        </Link>{" "}
        &nbsp;|&nbsp;{" "}
        <Link to="/terms" className="text-[#243677] cursor-pointer">
          Terms and Conditions
        </Link>
        &nbsp;|&nbsp;{" "}
        <Link to="/services/terms" className="text-[#243677] cursor-pointer">
          Rebate T&C
        </Link>
      </div>
    </footer>
  );
};

export default FooterComponent;
