import React from "react";
import Landing from "../../../themes";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Button from "../../../components/ui/links/Button";

const StandardPackage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <Landing>
      <div className="font-abel">
        <div className="flex md:ml-16 ml-4 font-abel my-6">
          <div className="flex justify-between items-center cursor-pointer text-[#243677]">
            <Link to="/">
              <BsArrowLeft size={25} />
            </Link>
            <Link to="/">
              <span className="ml-2 px-1 text-[16px] underline">Home</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="/services/packages">
              <span className="px-1 text-[16px] underline">Packages</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="#">
              <span className="px-1 text-[16px] underline">Standard Plus</span>
            </Link>
          </div>
        </div>

        <section className="relative">
          <div className="py-6 md:py-6 font-abel md:px-16 bg-corporatebg bg-cover h-full w-full md:h-[660px] absolute z-0"></div>
          <div className="max-w-[1350px] m-auto w-full px-6 pt-10 pb-32 relative z-10">
            <div className="grid md:grid-cols-4">
              <div className="md:col-span-3 order_2 bg-[#F2F2F2] h-full">
                <div className="bg-[#ffffff] px-4 md:px-8 py-4 md:py-5">
                  <div className="max-w-[748px] mx-auto">
                    <h1 className="md:text-5xl text-2xl pb-2 md:leading-10">
                      Standard Plus
                      <br />
                      <span className="md:text-4xl text-lg">
                        (Entry to Mid-Level)
                      </span>
                    </h1>
                    <p className="text-[15px] text-[#4E4E4E] max-w-[758px] pt-3">
                      This package is designed for entry to mid-level positions,
                      offering basic recruitment services to help you identify
                      the right talent. The fee is 17.5% of the candidate's
                      salary, with a guarantee of 2 months if the candidate
                      doesn't work out.
                    </p>
                  </div>
                </div>
                <div className="text-[#000000] py-4 px-4 md:px-8 font-abel">
                  <div className="max-w-[748px] mx-auto">
                    <h2 className="md:text-[42px] text-3xl pb-3">Features</h2>
                    <ul className="package-bullet md:text-[18px] text-[15px] leading-7 font-abel">
                      <li>
                        Basic Assessment Testing: Includes aptitude and
                        personality assessments to assess the candidate's
                        general capabilities and fit.{" "}
                      </li>
                      <li>
                        Basic Sourcing and Screening: Candidates are sourced
                        from job boards and databases, with an initial screening
                        based on qualifications and role fit.{" "}
                      </li>
                      <li>
                        Market Mapping: Provides a broad understanding of the
                        candidate pool in your industry.
                      </li>
                    </ul>
                    <p className="md:text-[18px] text-[15px] pt-3 font-abel">
                      3 Interview Stages:
                    </p>
                    <ul className="package-bullet md:text-[18px] text-[15px] leading-7 font-abel">
                      <li>
                        Preliminary situational/behavioral screening to
                        understand past job performance.
                      </li>
                      <li>
                        Technical screening to evaluate specific skills related
                        to the job.
                      </li>
                      <li>
                        Leadership style and culture fit screening to ensure
                        alignment with your organizational culture.
                      </li>
                      <li className="pt-3">
                        Standard Reporting: Regular updates on progress and
                        insights into candidates.
                      </li>
                    </ul>
                    <div className="pt-12 md:pb-6 md:-ml-8 flex md:justify-start justify-center">
                      <Button
                        onClick={() => navigate("/services/packages")}
                        className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[18px] text-[14px] hover:text-[black] hover:bg-[white] w-[200px] h-[50px] md:w-[253px] md:h-[65px] flex justify-center mb-6"
                      >
                        <span className="text-center">
                          Explore Additional Services
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 order_1 mb-3">
                <div className="bg-[#203171]">
                  <div>
                    <div className="md:bg-[#F2F2F2] bg-[#FFFFFF] px-5 py-2 ">
                      <h3 className="text-[25px] md:text-4xl text-[#0B60D6] max-w-[140px] mx-auto">
                        Rebates
                      </h3>
                    </div>
                    <div className="text-[#ffffff] bg-[#203171] px-6 py-2">
                      <div className="max-w-[140px] mx-auto pb-3">
                        <p className="text-[22px]">2 roles: 16.5%</p>
                        <p className="text-[22px]">3 roles: 16%</p>
                        <p className="text-[22px]">4 roles: 15.5%</p>
                        <p className="text-[22px]">5 roles: 15%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <Link
                to="/services/terms"
                className="md:text-[18px] text-[15px] text-[#4787E0]"
              >
                *T&C apply
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Landing>
  );
};

export default StandardPackage;
