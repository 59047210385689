import React from "react";
import Landing from "../../../themes";
import Button from "../../../components/ui/links/Button";
import { useNavigate } from "react-router-dom";

const RecruitmentPackagesTerms: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Landing className="font-abel">
      <div className="max-w-[1250px] mx-auto px-3 md:py-12 py-8 text-[#000000]">
        <div>
          <h1 className="text-3xl md:text-5xl font-[700] pb-3">
            Terms and Conditions
          </h1>
          <section className="font-abel my-4">
            <p className="text-[15px] md:text-[20px] pb-2">Eligibility</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                This package is available for new recruitment roles or repeat
                placements requested within six (6) months of the initial
                placement.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">Rebate Value</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                The rebate is calculated as a percentage of the placement fee or
                as otherwise specified in the package details.
              </li>
              <li>
                Rebates cannot be exchanged for cash or combined with other
                offers or discounts.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">
              Replacement Guarantee
            </p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                The rebate is contingent upon the candidate completing the
                agreed probationary period. If the candidate leaves or is
                terminated within this period, the rebate will be voided, and a
                free replacement will be provided under the terms of our
                replacement policy.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">Payment Terms</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                Full payment for recruitment services must be made within the
                agreed timeline for the rebate to apply. Late payments will
                disqualify the client from receiving the rebate.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">
              Client Responsibilities
            </p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                The client must provide a detailed job description and ensure
                timely feedback during the recruitment process. Delays caused by
                the client may affect eligibility for the rebate.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">Exclusions</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                The rebate does not apply to temporary roles, part-time
                positions, or roles below a certain salary threshold (to be
                defined in the package).
              </li>
              <li>
                Executive-level placements or specialized roles may be subject
                to different terms.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">Validity</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                The rebate is valid for a limited time and must be claimed
                within the period specified in the package agreement if any.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">
              Cancellation or Termination
            </p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                In the event the client cancels the recruitment process after
                candidates have been shortlisted, the rebate will be forfeited.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">
              Dispute Resolution
            </p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                Any disputes related to the rebate package will be addressed in
                accordance with the terms outlined in the service agreement.
              </li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2">Amendments</p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000] mb-4"
            >
              <li>
                We reserve the right to amend these terms at any time. Notice
                will be provided to clients for any significant changes.
              </li>
            </ul>
            <p className="text-[20px] md:text-[35px] pt-2">
              Background Check Policy
            </p>
            <p className="text-[15px] md:text-[20px] pb-2">
              Our basic background check includes:
            </p>
            <ul
              typeof="square"
              className="privacy-list text-[15px] md:text-[20px] text-[#000000]"
            >
              <li>Reference verification</li>
              <li>Employment verification</li>
              <li>Guarantor checks</li>
            </ul>
            <p className="text-[15px] md:text-[20px] pb-2 mb-4">
              Any additional checks will incur an extra cost. Please note that
              only our VIP & VIPP packages offers an all-inclusive cost for
              comprehensive background checks.
            </p>
            <p className="text-[20px] md:text-[35px]">
              Offer Letter Draft Review
            </p>
            <p className="text-[15px] md:text-[20px] pb-2 mb-4">
              While we will apply our combined expertise to ensure the offer
              letter is structured as a legally sound document, 25th & Staffing
              and its legal partners cannot be held liable for any disputes or
              legal issues that may arise from its use.
            </p>
            <p className="text-[15px] md:text-[20px] pb-2 mb-4">
              Based on our extensive experience, we believe our recommendations
              are robust and can withstand legal scrutiny. However, we strongly
              advise you to seek independent legal counsel to review the
              document for additional assurance.
            </p>
            <p className="text-[15px] md:text-[20px] pb-2 mb-4">
              Our draft will be submitted in good faith, but 25th & Staffing and
              its affiliates accept no liability for any legal or contractual
              outcomes.
            </p>
            <p className="text-[20px] md:text-[35px]">Terms and Limitations</p>
            <p className="text-[15px] md:text-[20px] pb-2 mb-4">
              All terms and limitations apply solely to the number of rebates
              per role. This does not constitute an agreement beyond the
              specified rebated roles. Any services or requests outside the
              scope of these terms will incur additional costs.
            </p>
            <div className="pt-8 pb-6 md:-ml-8 flex md:justify-start justify-center">
              <Button
                onClick={() => navigate("/services/packages")}
                className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[18px] text-[14px] hover:text-[black] hover:bg-[white] w-[200px] h-[50px] md:w-[243px] md:h-[59px] flex justify-center mb-6"
              >
                <span className="text-center">Explore Additional Services</span>
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Landing>
  );
};

export default RecruitmentPackagesTerms;
