import React from "react";
import Landing from "../../../themes";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import Button from "../../../components/ui/links/Button";

const ExecutivePackage: React.FC = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <Landing>
      <div className="font-abel">
        <div className="flex md:ml-16 ml-4 font-abel my-6">
          <div className="flex justify-between items-center cursor-pointer text-[#243677]">
            <Link to="/">
              <BsArrowLeft size={25} />
            </Link>
            <Link to="/">
              <span className="ml-2 px-1 text-[16px] underline">Home</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="/services/packages">
              <span className="px-1 text-[16px] underline">Packages</span>
            </Link>
            <span className="px-1 text-[27.66px]">/</span>
            <Link to="#">
              <span className="px-1 text-[16px] underline">
                Executive Package
              </span>
            </Link>
          </div>
        </div>

        <section className="relative">
          <div className="py-6 md:py-6 font-abel md:px-16 bg-corporatebg bg-cover h-full w-full md:h-[660px] absolute z-0"></div>
          <div className="max-w-[1350px] m-auto w-full px-6 pt-10 pb-32 relative z-10">
            <div className="grid md:grid-cols-4">
              <div className="md:col-span-3 order_2 bg-[#F2F2F2] h-full">
                <div className="bg-[#000000] text-[#ffffff] px-4 md:px-12 py-4 md:py-5">
                  <div className="max-w-[748px] mx-auto">
                    <h1 className="md:text-5xl text-2xl pb-2 md:leading-10">
                      Executive Package
                      <br />
                      <span className="md:text-4xl text-lg">
                        (Senior Management)
                      </span>
                    </h1>
                    <p className="text-[15px] text-[#ffffff] max-w-[758px] pt-3">
                      Targeted for senior management roles, this package offers
                      a more in-depth process, including additional assessment
                      and a longer guarantee period. The fee is 20% of the
                      candidate's salary, with a guarantee of 3 months if the
                      candidate doesn't work out.
                    </p>
                  </div>
                </div>
                <div className="text-[#000000] py-4 px-4 md:px-8 font-abel">
                  <div className="max-w-[748px] mx-auto">
                    <h2 className="md:text-[42px] text-3xl pb-3">Features</h2>
                    <ul className="package-bullet md:text-[18px] text-[15px] leading-9 font-abel">
                      <li>
                        Executive Mapping Analysis: Providing a comprehensive
                        overview of leadership talent and organizational
                        alignment.
                      </li>
                      <li>
                        In-Depth Market Analysis: Detailed research into the
                        market and competitor hiring trends.
                      </li>
                      <li>
                        Enhanced Screening Process: Focused on targeted
                        headhunting, referrals, and specialized technical
                        communities.
                      </li>
                      <li>
                        3-Step Assessment Testing: Includes aptitude tests,
                        personality assessments, and technical skills evaluation
                        to assess multiple dimensions of candidates.
                      </li>
                      <li>
                        Basic Background Check: Verifies the candidate’s history
                        and qualifications.
                      </li>
                      <li>
                        Bi-weekly Reporting: Regular, in-depth updates on
                        progress and candidate evaluations.
                      </li>
                      <li>
                        Replacement Guarantee: If the hired candidate leaves
                        within 3 months, they will be replaced at no additional
                        cost.
                      </li>
                      <li>
                        Dedicated Consultant: A consultant will work with you
                        throughout the process.
                      </li>
                      <li>
                        Offer Letter Draft: Assistance in drafting the
                        candidate’s offer letter.
                      </li>
                      <li>
                        Shortlist Guarantee: A guarantee of providing a
                        shortlist of qualified candidates.
                      </li>
                      <li>
                        CEO Pick: A candidate shortlist personally curated from
                        our management team.
                      </li>
                      <li>
                        Salary Negotiation Support: Helps define total
                        compensation based on market positioning.
                      </li>
                    </ul>

                    <div className="pt-12 md:pb-6 md:-ml-8 flex md:justify-start justify-center">
                      <Button
                        onClick={() => navigate("/services/packages")}
                        className="text-[#0B60D6] border border-[#8F8D8D] btn_border md:text-[18px] text-[14px] hover:text-[black] hover:bg-[white] w-[200px] h-[50px] md:w-[253px] md:h-[65px] flex justify-center mb-6"
                      >
                        <span className="text-center">
                          Explore Additional Services
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 order_1 mb-3">
                <div className="bg-[#203171]">
                  <div>
                    <div className="bg-[#FFFFFF] px-5 py-2 ">
                      <h3 className="text-[25px] md:text-4xl text-[#0B60D6] max-w-[140px] mx-auto">
                        Rebates
                      </h3>
                    </div>
                    <div className="text-[#ffffff] bg-[#203171] px-6 py-2">
                      <div className="max-w-[140px] mx-auto pb-3">
                        <p className="text-[22px]">2 roles: 19%</p>
                        <p className="text-[22px]">3 roles: 18.5%</p>
                        <p className="text-[22px]">4 roles: 18%</p>
                        <p className="text-[22px]">5 roles: 17.5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <Link
                to="/services/terms"
                className="md:text-[18px] text-[15px] text-[#4787E0]"
              >
                *T&C apply
              </Link>
            </div>
          </div>
        </section>
      </div>
    </Landing>
  );
};

export default ExecutivePackage;
